import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelpsteps from "src/components/chromelpstepsnew"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_carbonate from "src/components/btf_carbonate"
import InstallSteps from "src/components/installSteps"


const chromelpData = import("./data/data.json");
const btfData = import("./data/btfData.json");
const installData = import("./data/installdata.json");

export default function directchromasteps() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       

       .footerlinks-module--br {
         display: none!important;
       }

        #chromelpsteps-module--chromelp .chromelpsteps-module--main {
        height: 100vh;
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Get Curated Content and Apps - Carbonate</title></Helmet>
    <section>
      <Chromelpsteps data={chromelpData}><InstallSteps data={installData}></InstallSteps></Chromelpsteps>
      <Btf_carbonate data={btfData}></Btf_carbonate>
      </section>
    </HomepageLayout>
  )
}
